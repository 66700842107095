
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  message,
  Switch,
  Select,
  Upload,
  Image,
} from "ant-design-vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import {
  updateholidayByCode,
  getHolidayByDate
} from "@/api/terminalList.js";
import { AreaRules } from "@/rules/rules.js";
const { Item } = Form;
const { Option } = Select;
export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    Switch,
    PlusOutlined,
    Select,
    Option,
 
    Upload,
    CloseOutlined,
    Image,

  },
  data() {
    return {
      formState: {
      },
      //rules: AreaRules,
      //是否关联包装单位
    };
  },
  methods: {
    onSubmit() {
      (this as any).$refs.formRef
        .validate()
        .then(() => {
          let parameters: any = { ...this.formState };
           
          if (this.$route.params.date != "new") {
            updateholidayByCode({
              date: this.$route.params.date,
              info: parameters.info,
              holiday: parameters.holiday,
              name: parameters.name,
              isnotwork: parameters.isnotwork,
              wage: parameters.wage,
            }).then((res) => {
               
              if (res.data.success) {
                message.success("编辑节假日成功");
                this.$router.push("/masterData/holiday/list");
              }
            });
          } 
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    },
    cancel() {
      history.go(-1);}
    },
  mounted() {
    
    if (this.$route.params.date !== "new") {
      (this as any).$store.commit("changeBreadcrumb", [
        "主数据中心",
        "节假日管理",
        "节假日详情",
      ]);
      //获取数据
      getHolidayByDate(this.$route.params.date).then((res: any) => {
        let result: any = res.data.data;
         
        this.formState=result
      });
    } else {
      (this as any).$store.commit("changeBreadcrumb", [
        "主数据中心",
        "地区管理",
        "新增地区",
      ]);
    }
  },
});
